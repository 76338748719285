import { useRouter } from 'next/router';

type Routes =
  | 'about-us'
  | 'account'
  | 'apps'
  | 'brands'
  | 'blog'
  | 'faq'
  | 'forgot-password'
  | 'friends'
  | 'international'
  | 'jobs'
  | 'homepage'
  | 'legal'
  | 'login'
  | 'profile'
  | 'refer-a-friend'
  | 'routine'
  | 'shop'
  | 'store'
  | 'summary'
  | 'surveys'
  | 'sustainability';

type ActiveRouteMapType = {
  [key in Routes]: boolean;
};

interface Active {
  isActive: ActiveRouteMapType;
  isAcquisitionRoute: boolean;
  isAnonymousRoute: boolean;
  isBasicLayout: boolean;
  isChatAllowed: boolean;
  isLoginLogout: boolean;
  isNavForest: boolean;
  isNavPine: boolean;
  isSustainability: boolean;
}

/**
 * @name useRoutes
 * @description This hook is used to provide boolean checks for the current route.
 */
export const useRoutes = (): Active => {
  // Hooks
  const { pathname } = useRouter();

  /**
   * Useful for determining which route is active.
   */
  const isActive: ActiveRouteMapType = {
    'about-us': pathname === '/about-us',
    account: pathname === '/profile/account',
    apps: pathname.startsWith('/routine'), // demo/prototypes
    blog: pathname.startsWith('/blog'),
    brands: pathname.startsWith('/brands'),
    faq: pathname === '/faq',
    'forgot-password': pathname === '/forgot-password',
    friends: pathname === '/profile/friends',
    homepage: pathname === '/',
    international: pathname === '/lp/international',
    jobs: pathname === '/jobs',
    legal: pathname.startsWith('/legal'),
    login: pathname === '/login',
    profile: pathname.startsWith('/profile'), // user-facing
    'refer-a-friend': pathname === '/refer-a-friend',
    routine: pathname === '/profile/routine',
    shop: pathname.startsWith('/shop'), // includes shop-mama
    store: pathname.startsWith('/store'),
    summary: pathname === '/profile/summary',
    surveys: pathname.startsWith('/surveys'),
    sustainability: pathname === '/sustainability'
  };

  const isChatAllowed = isActive.profile || isActive.store || isActive.shop;
  const isLoginLogout = isActive.login || isActive['forgot-password'];
  const isGenericRoute =
    isActive['about-us'] ||
    isActive.brands ||
    isActive['blog'] ||
    isActive.homepage ||
    isActive.international ||
    isActive['refer-a-friend'];
  const isInfoRoute =
    isActive.faq || isActive.jobs || isActive.legal || isActive.sustainability;
  const isAnonymousRoute = isActive.shop;

  // Global pages vs user-facing stores
  const isAcquisitionRoute = isGenericRoute || isLoginLogout || isInfoRoute;

  // These are mutually exclusive, all other routes are considered generic with white background
  const isBasicLayout = isActive.surveys || isActive.apps;
  const isNavForest = isActive.homepage || isActive['refer-a-friend'];
  const isNavPine = isActive.brands;
  const isSustainability = isActive.sustainability;

  return {
    isAcquisitionRoute,
    isActive,
    isAnonymousRoute,
    isBasicLayout,
    isChatAllowed,
    isLoginLogout,
    isNavForest,
    isNavPine,
    isSustainability
  };
};
